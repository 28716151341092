.topSpace {
    margin: 20px;
    margin-top: 100px;
    height: 70vh;
}

.tableSection {
    margin-left: 20px;


}

.topthrade {
    background-color: black;
    color: #fff;
}

.green {
    color: green;
}

.FormLogin {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("https://wallpapercave.com/wp/wp2528637.jpg");
    background-size: contain;
    filter: xray(90%);

}

.forForm {

    position: absolute;
    left: 0px;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
}
.FormRegister {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("https://wallpapercave.com/wp/wp2528637.jpg");
    background-size: contain;
    filter: xray(90%);

}

.forFormRegister {

    position: absolute;
    left: 0px;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
}
.topSpace {
    margin: 20px;
    margin-top: 100px;
}

.tableSection {
    margin-left: 20px;


}

.loginMain {
    height: 80vh;
    margin: auto;
    width: 50%;
    margin-top: 70px;
}